<!-- 课堂回放 -->

<style lang="scss" scoped>
	.page{
		padding: 32px;
		width: 100%;
		max-width: 700px;
		margin:  0 auto;
        height: calc(100vh - 150px);
        overflow: auto;
	}
	::v-deep .video-player{
		margin-bottom: 20px;
	}
	.video,.video{
		width: 100%;
		height: 100px;
		border-radius: 14px;
	}
	.file{
		width: 100%;
		height: 140px;
		background: #FDFDFD;
		box-shadow: 0px 14px 40px -17px #B7D0DE;
		border-radius: 14px;
		.fileimg{
			width: 70px;
			max-height: 200px;
		}
	}
	.content{
		margin-top: 20px;
		background: #FFFFFF;
		border-radius: 14px;
		padding: 20px;
		.course{
			border-radius: 6px;
			height: 32px;
			padding: 0 10px;
			font-size: 18px;
			display: inline-block;
			line-height: 32px;
			text-align: center;
		}
		.name{
			margin-top: 5px;
			font-size: 18px;
			font-weight: bold;
			color: #222222;
			line-height: 28px;
		}
		.userInfo{
			width: 100%;
			margin-top: 20px;
			.photo{
				width: 50px;
				height: 50px;
				border-radius: 50%;
				margin-right: 10px;
			}
			.teacherName{
				font-size: 16px;
				font-weight: 400;
				color: #444444;
				line-height: 20px;
			}
			.teacherTitle{
				margin-top: 6px;
				height: 28px;
				font-size: 14px;
				font-weight: 400;
				color: #888888;
				line-height: 28px;
			}
		}
		.handle{
			padding: 16px 0px 0px;
			width: 100%;
			text-align: center;
			.combtn1{
				background-color: #E5F5FE;
				color: $comBlue;
				line-height: 72px;
				height: 72px;
			}
			.combtn3{
				line-height: 72px;
				height: 72px;
			}
		}
	}
</style>

<template>
    <div class="page">
		<div v-if="teclaInfo.tecla_id">
			<div v-if="teclaInfo.teles_cou_video">
				<video-player
				class="video-player vjs-custom-skin"
				ref="videoPlayer"
				:playsinline="true"
				:options="playerOptions"
				/>
			</div>
			<div class="file flex_content_center" v-if="teclaInfo.teles_cou_url">
                <img class="fileimg" src="@assets/images/studentwps.png" alt="">
			</div>
			<div class="content" v-if="teclaInfo.teuse_name">
				<div class="course">{{teclaInfo.sysub_name}}</div>
				<div class="name">{{teclaInfo.sccou_cha_title}}</div>
				<div class="userInfo flex_align">
                    <img class="photo" :src="teclaInfo.teuse_image?teclaInfo.teuse_image:require('@assets/images/empty_avatar.png')" alt="">
					<div class="info">
						<div class="teacherName">{{teclaInfo.teuse_name}}</div>
						<div class="teacherTitle">授课老师</div>
					</div>
				</div>
                <div class="handle" v-if="teclaInfo.teles_cou_url">
                    <el-button
                        type="custom_primary"
                        size="medium"
                        @click="downloadFile" >
                        下载
                    </el-button>
				</div>
			</div>
		</div>
		
		<div class="no-data--empty" v-if="!teclaInfo.tecla_id">
			<img src="@assets/images/no-data3.png" alt="">
			<p>暂无详情</p>
		</div>
	</div>
</template>

<script>
import { get_courseware_data } from "@api/home"
import { formatFile } from "@utils";
export default {
    name: 'schoolPlay',
    data() {
        return {
            //课程id
            tecla_id:0,
            //课程信息
            teclaInfo:{},
            btnLoad: false,
             /* 视频播放器配置 */
            playerOptions: {
                playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                autoplay: false, // 如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [],
                poster: "", // 视频封面
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controls: true,
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true, // 全屏按钮
                },
            },
        }
    },
    created() {
        this.tecla_id=this.$route.params.id
        this.getData();
    },
    methods: {
       
        /** 获取公开课列表数据 */
        async getData() {
            let { data } = await get_courseware_data({
                tecla_id:this.tecla_id,
            });
			console.log(data)
            this.playerOptions.sources =[{type: "video/mp4", src: formatFile(data.teles_cou_video) }]
            this.teclaInfo=data
            this.$forceUpdate();
        },
        /** 文件下载 */
        downloadFile() {
            if (this.teclaInfo.teles_cou_url) {
                this.$fileDownload(this.teclaInfo.teles_cou_url);
            }
        },
      
    },
}
</script>